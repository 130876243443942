@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');

html {
    font-family: 'Roboto', sans-serif;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    overflow-y: overlay;
}

::-webkit-scrollbar {
    background: #979797;
}

::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
    background: #e9e9e9;
}